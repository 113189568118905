/* You can add global styles to this file, and also import other style files */
@import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
@import '@ag-grid-community/all-modules/dist/styles/ag-theme-dark.css';
@import '@ag-grid-community/all-modules/dist/styles/ag-grid.scss';
@import '@ag-grid-community/all-modules/dist/styles/mixins/ag-theme-mixins.scss';


// embed the Material font
@import '@ag-grid-community/all-modules/dist/styles/webfont/agGridMaterialFont.scss';
.temp-color {
  background-color: #eaeaea;
}

/*.user-list {
  display: flex;
  flex-flow: column nowrap;
  width: 500px; }
li {
  margin-left: 20px;
}
.ml-50 {
  margin-left: 15em;
}
input[type="radio"]
{
  display: inline-block;
  position: absolute;
  right: 50px;
}*/
.ag-theme-dark {
  color: #333 !important;
  background-color: transparent !important;
  font-size: 13px !important;
  font-family: 'proxima-nova', arial, helvetica, sans-serif !important;
}

.ag-theme-dark .ag-root-wrapper {
  background-color: transparent !important;
  border: 0px !important;
}

.ag-theme-dark .ag-root-wrapper-body {
  border: solid 1px darkgrey !important;
}

.ag-theme-dark .ag-row {
  color: #333 !important;
  font-size: 13px !important;
}

.ag-theme-dark .ag-header-row {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  font-family: 'proxima-nova', arial, helvetica, sans-serif !important;
  height: 28px !important;
  min-height: 28px !important;
}

.ag-theme-dark .ag-header-cell {
  border: 0px !important;
  background-color: rgb(51, 51, 51) !important;
}

.ag-theme-dark .ag-ltr .ag-cell {
  border: 0px !important;
}

.ag-theme-dark .ag-header,
.ag-theme-dark .ag-paging-panel {
  background-color: #000 !important;
}
.ag-theme-dark .ag-row-even {
  background-color: #fdfdfd !important;
}

.ag-theme-dark .ag-row-odd {
  background-color: #e9e9e9 !important;
}

.ag-header-cell-label .ag-header-icon.ag-sort-order {
  display: none !important;
}

.ag-theme-dark .ag-icon {
  font-size: 20px !important;
}

.ag-theme-dark .ag-icon {
  text-transform: none !important;
  line-height: 1 !important;
  padding: 0 3px !important;
  vertical-align: middle !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: inline-flex !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: 1.25rem !important;
  &-asc {
    &:before {
      content: '\e618' !important;
    }
  }
  &-desc {
    &:before {
      content: '\e617' !important;
    }
  }
  &-first {
    &:before {
      color: #333 !important;
      content: '\e617' !important;
    }
  }
  &-previous {
    &:before {
      color: #333 !important;
      content: '\ea1c' !important;
    }
  }
  &-next {
    &:before {
      color: #333 !important;
      content: '\ea1d' !important;
    }
  }
  &-last {
    &:before {
      color: #333 !important;
      content: '\ea20' !important;
    }
  }
}

.ag-paging-panel {
  padding: 0 !important;
  > span {
    margin-left: 0 !important;
  }
  &.ag-unselectable {
    background: #fff !important;
    height: 40px !important;
    margin: 7px 0 0 0 !important;
    border-top: none !important;
  }
}

span.ag-paging-page-summary-panel {
  left: 0 !important;
  position: absolute !important;
  color: #333 !important;
  font-size: 13px !important;
  div {
    border: 1px solid #a1acb3 !important;
    padding: 2px !important;
    margin: 3px !important;
    border-radius: 3px !important;
    &.ag-disabled {
      .ag-icon {
        &:before {
          color: #d3d3d3 !important;
        }
      }
    }
  }
}

.ag-paging-row-summary-panel {
  font-size: 13px !important;
  color: #333 !important;
}

.ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
  border-color: transparent !important;
}

.ag-theme-dark .ag-header-cell::after,
.ag-theme-dark .ag-header-group-cell::after {
  background-color: transparent !important;
}

.ag-theme-dark .ag-icon-none:before {
  content: '\e618\e617' !important;
  letter-spacing: -0.6em;
}
.ag-theme-dark .ag-icon-menu:before {
  content: '\e90b' !important;
  font-size: 1rem;
}

.ag-theme-dark .ag-icon-small-down:before {
  content: '\e617' !important;
}

.ag-header {
  height: 45px;
  min-height: 45px;
}
.ag-header-row .ag-header-row-column {
  height: 45px;
}

.ag-header-cell-text {
  white-space: normal !important;
}

.ag-theme-dark .ag-header-row {
  height: 45px !important;
  min-height: 45px !important;
}

.ag-theme-dark .ag-menu,
.ag-theme-dark .ag-select .ag-picker-field-wrapper,
.ag-theme-dark input[class^='ag-'],
.ag-popup .ag-popup-child {
  background: #fff !important;
}

.no-data-display {
  background: white;
  position: absolute;
  left: 0;
  top: 3.5em;
}

.ag-overlay-no-rows-wrapper * {
  width: 98%;
}

#workflow-document-table-panel .ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 30px !important;
}

.ag-theme-dark .ag-icon-filter:before {
  font-family: agGridClassic !important;
  font-size: 10px;
  content: '\f112' !important;
}

.interactiveGrid .ag-theme-dark .ag-row-hover {
  background-color: #c9dde1 !important;
}
.interactiveGrid .ag-theme-dark .ag-row-selected {
  background-color: #002b45 !important;
}

.interactiveGrid .ag-theme-dark .ag-row-selected * {
  color: #ffffff !important;
}

.pending-status-bg {
  background-color: #DDE004 !important;
}
